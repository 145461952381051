<template>
    <div v-if="isAllowedTo('SupplierCentral/getPackageConfigurationsBySupplierId')">
        <h2 class="font-weight-normal ml-15 mr-15">Package configurations</h2>
        <transition-group class="d-flex fill" name="list" tag="div">
            <div
                v-for="packageConfiguration in packageConfigurations"
                :key="packageConfiguration.packageId"
                class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                <package-configuration :packageConfiguration="packageConfiguration"
                                       @editPackageConfiguration="editPackage"
                ></package-configuration>
            </div>
        </transition-group>
        <div
            v-if="packageConfigurations && packageConfigurations.length === 0"
            class="flex align-items-center justify-content-center flex-column"
        >
            <i class="fas fa-laptop-house no-configurations-icon"></i>
            <h1>No configurations found</h1>
            <h3 class="font-weight-normal mt-0">Please add one or more configurations</h3>
            <mercur-button
                    v-if="isAllowedTo('SupplierCentral/createPackageConfiguration')"
                    class="btn btn-raised btn-yellow text-uppercase" @click="addPackage">
                Create package configuration
            </mercur-button>
        </div>

        <mercur-button
            v-if="isAllowedTo('SupplierCentral/createPackageConfiguration')"
            class="btn btn-raised btn-yellow btn-icon btn-icon-lg add-configuration-icon"
            @click="addPackage">
            <i class="fas fa-plus"></i>
        </mercur-button>

        <mercur-dialog :is-open.sync="formConfigurationDialog">
            <div slot="header"><h3>{{formType}} package configuration</h3></div>

            <div slot="default" v-if="formDataPackageConfiguration">
                <form @submit.prevent="savePackageConfiguration">
                    <div class="mb-40">
                        <h3>General</h3>
                        <p class="mb-0">
                            Please enter the package name for your new package configuration.
                        </p>
                        <mercur-input
                            v-model="formDataPackageConfiguration.packageName"
                            :disabled="formConfigurationIsLoading"
                            required>
                            Package name
                        </mercur-input>
                    </div>
                    <div class="mb-40">
                        <h3>Dimensions</h3>
                        <div class="configuration-add__fields">
                            <div v-for="dimensionKey in dimensionKeys" :key="dimensionKey" class="configuration-add__dimension-field">
                                <mercur-input
                                    v-model.number="formDataPackageConfiguration[dimensionKey]"
                                    type="number"
                                    step="1"
                                    :disabled="formConfigurationIsLoading || !isAdding"
                                    required>
                                    {{dimensionKey | beautify}}
                                    <span slot="suffix">mm</span>
                                </mercur-input>
                            </div>
                            <div class="configuration-add__volume">
                                <p class="configuration-add__volume-key m-0">Volume</p>
                                <p class="configuration-add__volume-value m-0">
                                    {{ getVolumeForDimensions(formDataPackageConfiguration) / 1000 }}cm&sup3;
                                    <mercur-tooltip>
                                        <template slot="label">This value is automatically calculated</template>
                                    </mercur-tooltip>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3>Weight</h3>
                        <p class="mb-0">In order to calculate to correct shipping costs we need to include the tare weight of the package</p>
                        <mercur-input
                            v-model.number="formDataPackageConfiguration.weightTare"
                            type="number"
                            step="1"
                            :disabled="formConfigurationIsLoading || !isAdding"
                            required>
                            Tare weight
                            <span slot="suffix">g</span>
                        </mercur-input>
                    </div>
                    <div class="text-right">
                        <mercur-button
                            class="btn btn-raised text-uppercase"
                            @click="formConfigurationDialog = false"
                            :disabled="formConfigurationIsLoading">
                            Cancel
                        </mercur-button>
                        <mercur-button
                            type="submit"
                            :disabled="formConfigurationIsLoading"
                            class="btn btn-raised btn-yellow text-uppercase">
                            Save
                        </mercur-button>
                    </div>
                </form>
            </div>
        </mercur-dialog>
    </div>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import PackageConfiguration from '@/components/packages/PackageConfiguration'

export default {
    name: 'SupplierPackageConfigurations',
    components: { PackageConfiguration },
    data () {
        return {
            url: CONFIG.API.ROUTES.SUPPLIERS.PACKAGES_CONFIGURATION.OVERVIEW.replace('{supplierId}', this.supplierId),
            packageConfigurations: null,
            formType: null,
            formConfigurationIsLoading: false,
            formConfigurationDialog: false,
            packageConfigurationData: {
                packageConfigurationId: null,
                packageName: null,
                weightTare: null,
                packageWidth: null,
                packageHeight: null,
                packageLength: null,
            },
            dimensionKeys: [
                'packageWidth',
                'packageLength',
                'packageHeight',
            ],
            formDataPackageConfiguration: null,
            isAdding: true,
        }
    },

    methods: {
        getVolumeForDimensions (packageConfiguration) {
            return packageConfiguration.packageWidth * packageConfiguration.packageLength * packageConfiguration.packageHeight
        },
        editPackage (packageConfiguration) {
            this.packageConfigurationData = packageConfiguration
            this.isAdding = false
            this.formDataPackageConfiguration = JSON.parse(JSON.stringify(this.packageConfigurationData))
            this.openPackageConfigurationFormDialog()
        },
        addPackage () {
            this.isAdding = true
            this.formDataPackageConfiguration = {}
            this.openPackageConfigurationFormDialog()
        },
        openPackageConfigurationFormDialog () {
            this.formConfigurationDialog = true
            this.formType = this.isAdding ? 'Add' : 'Edit'
        },
        savePackageConfiguration () {
            this.formConfigurationIsLoading = true
            let url = ''
            if (this.isAdding) {
                url = CONFIG.API.ROUTES.SUPPLIERS.PACKAGES_CONFIGURATION.ADD
                    .replace('{supplierId}', this.supplierId)
            } else {
                url = CONFIG.API.ROUTES.SUPPLIERS.PACKAGES_CONFIGURATION.UPDATE
                    .replace('{supplierId}', this.supplierId)
                    .replace('{packageId}', this.packageConfigurationData.packageId)
            }
            this.addJob(url)
            if (!this.formDataPackageConfiguration.packageType) {
                this.formDataPackageConfiguration.packageType = 'UNIT'
            }
            this.$api.post(url, this.formDataPackageConfiguration).then(() => {
                this.formConfigurationDialog = false
                if (this.isAdding) {
                    this.packageConfigurations.unshift({
                        ...this.formDataPackageConfiguration,
                        packageId: Math.random(),
                    })
                } else {
                    this.packageConfigurations.some((packageConfiguration, index) => {
                        if (packageConfiguration.packageId === this.formDataPackageConfiguration.packageId) {
                            this.packageConfigurations[index] = this.formDataPackageConfiguration
                        }
                    })
                }
                this.formDataPackageConfiguration = null
                this.$root.$emit('notification:global', {
                    message: `Package configuration saved successfully`,
                })
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Saving package configuration failed. Please try again.`,
                    type: 'error',
                })
                if (CONFIG.DEBUG) {
                    console.log(data)
                }
            }).finally(() => {
                this.formConfigurationIsLoading = false
                this.finishJob(url)
            })
        },
    },
    created () {
        this.addJob(this.url)
        this.$api.get(this.url)
            .then(({ data }) => {
                this.packageConfigurations = data.data.items
            })
            .catch(() => {
                this.$root.$emit('notification:global', {
                    message: `Fetching available packages failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.finishJob(this.url)
            })
    },
}
</script>

<style lang="scss" scoped>
.configuration-add {
    &__volume {
        color: rgba(0, 0, 0, 0.54);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        margin-top: 10px;

        p {
            flex: 1 0 50%;
        }

        &-key {
            text-align: right;
            padding-right: 10px;
        }
        &-value {
            text-align: left;
        }
    }
}

.list-enter-active, .list-leave-active {
    transition: all 500ms;
}
.list-enter, .list-leave-to {
    opacity: 0;
    transform: scale(0);
}
.no-configurations-icon {
    font-size: 7rem;
    color: gray;
}
.add-configuration-icon {
    position: fixed;
    right: 30px;
    bottom: 30px;
}
</style>
